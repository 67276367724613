import React, { useContext, useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { 
  TrendingUp, 
  Users, 
  DollarSign, 
  Clock,
  ChevronDown,
  Calendar
} from 'lucide-react';
import './BeauticianStats.css';
import Swal from 'sweetalert2';
import appointmentApi from '../../../api/appointement.api';
import userApi from '../../../api/user.api';
import { AppContext } from '../../../contexts/AppContext';
import serviceCategoriesApi from '../../../api/service-categories.api';
import { beauticiansSubCategoriesApi } from '../../../api/beauticians_subcategories.api';
import availabilityApi from '../../../api/availability.api';

const formatDate = (date) => {
  const targetDate = new Date(date);
  const options = { month: 'long' };
  const result = targetDate.toLocaleDateString('fr-FR', options);
  return result.charAt(0).toLocaleUpperCase() + result.slice(1, 3);
};

const BeauticianStats = () => {
  const { connectedUser } = useContext(AppContext);
  const [timeFrame, setTimeFrame] = useState('month');

  const [revenueData, setRevenueData] = useState([
    // { month: 'Jan', revenue: 2400 },
  ]);

  const [servicesData, setServicesData] = useState([])

  const [kpis, setKpis] = useState([
    {
      title: "Chiffre d'affaires",
      value: "0€",
      change: "0",
      icon: <DollarSign size={24} />,
      trend: "up"
    },
    {
      title: "Nouveaux clients",
      value: "0",
      change: "0",
      icon: <Users size={24} />,
      trend: "up"
    },
    {
      title: "Taux occupation",
      value: "100%",
      change: "0%",
      icon: <Clock size={24} />,
      trend: "up"
    },
    {
      title: "Rendez-vous",
      value: "0",
      change: "0",
      icon: <Calendar size={24} />,
      trend: "up"
    }
  ]);

  const getRevenueData = async () => {
    try {
      const token = localStorage.getItem("token");
      const user = await userApi.fetchBeauticianUserByUserId(token, connectedUser.id);

      if(user) {
        const result = await appointmentApi.getAppointmentByBeautician(user.beautician_id);

        const rendezVous = result.length
        const nouveauxClients = result.filter(r => new Date(r.created_at) > new Date()).length
        const chiffreDAffaire = result.filter(r => r.status === "confirmed" || r.status === "completed")
          .reduce((acc, {total_prices}) => acc + parseInt(total_prices), 0)

        setKpis(kpis.map(k => {
          if(k.title === "Chiffre d'affaires")
            return {
              title: "Chiffre d'affaires",
              value: `${chiffreDAffaire}€`,
              change: "0",
              icon: <DollarSign size={24} />,
              trend: "up"
            }
          else if(k.title === "Nouveaux clients")
            return {
              title: "Nouveaux clients",
              value: nouveauxClients,
              change: "0",
              icon: <Users size={24} />,
              trend: "up"
            }
          else if(k.title === "Rendez-vous")
            return {
              title: "Rendez-vous",
              value: rendezVous,
              change: "0",
              icon: <Calendar size={24} />,
              trend: "up"
            }
          else
            return k
        }));

        setRevenueData(
          Object.values(result.filter(r => r.status === "confirmed" || r.status === "completed")
                .map(r => {
                  return {
                    month: formatDate(r.appointment_date),
                    revenue: parseFloat(r.total_prices)
                  }
                })
                .reduce((acc, {month, revenue}) => {
                  if(acc[month])
                    acc[month].revenue += revenue
                  else
                    acc[month] = { month, revenue }

                  return acc
                }, {})
        ));
      }
    }
    catch(error) {
      Swal.fire("Erreur", error.message, "error");
    }
  }

  const getServicesData = async () => {
    try {
      const token = localStorage.getItem("token");
      const user = await userApi.fetchBeauticianUserByUserId(token, connectedUser.id);

      if(user) {
        const beauticianSubCategories = await beauticiansSubCategoriesApi.getByBauticianId(user.beautician_id);

        console.log(beauticianSubCategories)

        const categoryCounts = Object.values(
          beauticianSubCategories.reduce((acc, item) => {
            if (!acc[item.beautician_subcategory_id]) {
              acc[item.beautician_subcategory_id] = {
                name: item.service_subcategory_name,
                value: 0
              };
            }
            acc[item.beautician_subcategory_id].value += 1; // Compte le nombre d'occurrences
            return acc;
          }, {})
        );
      
        const totalCount = categoryCounts.reduce((sum, category) => sum + category.value, 0);
        
        setServicesData(categoryCounts.map(category => ({
            name: category.name,
            value: category.value,
            percentage: ((category.value / totalCount) * 100).toFixed(2) // Pourcentage avec 2 décimales
          }))
        )
      }
    }
    catch(error) {
      Swal.fire("Erreur", error.message, "error");
    }
  }

  const getBeauticianAvailability = async () => {
    try {
      const token = localStorage.getItem("token");
      const user = await userApi.fetchBeauticianUserByUserId(token, connectedUser.id);

      if(user) {
        const [schedule, appointments] = await Promise.all([
          availabilityApi.getBeauticianAvailability(user.beautician_id),
          appointmentApi.getAppointmentByBeautician(user.beautician_id)
        ]);

        const totalAvailableMinutes = schedule.reduce((total, day) => {
          const workStart = new Date(`1970-01-01T${day.start_time}Z`);
          const workEnd = new Date(`1970-01-01T${day.end_time}Z`);
          const breakStart = new Date(`1970-01-01T${day.break_start}Z`);
          const breakEnd = new Date(`1970-01-01T${day.break_end}Z`);
        
          const morningMinutes = (breakStart - workStart) / (1000 * 60);
          const afternoonMinutes = (workEnd - breakEnd) / (1000 * 60);
          return total + morningMinutes + afternoonMinutes;
        }, 0);

        const currentDate = new Date();
        const upcomingAppointments = appointments.filter(appointment => {
          const appointmentDate = new Date(appointment.appointment_date);
          return appointment.status === "confirmed" && appointmentDate > currentDate;
        });

        const takenMinutes = upcomingAppointments.reduce((total, appointment) => {
          const start = new Date(`1970-01-01T${appointment.start_time}Z`);
          const end = new Date(`1970-01-01T${appointment.end_time}Z`);
          return total + (end - start) / (1000 * 60);
        }, 0);

        const availabilityPercentage = ((1 - takenMinutes / totalAvailableMinutes) * 100).toFixed(2);

        setKpis(kpis.map(k => {
          if(k.title === "Taux occupation")
            return {
              title: "Taux occupation",
              value: `${availabilityPercentage}%`,
              change: "0",
              icon: <Clock size={24} />,
              trend: "up"
            }
          else
            return k
        }));
      }
    }
    catch(error) {
      Swal.fire("Erreur", error.message, "error");
    }
  }

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  useEffect(() => {
    getBeauticianAvailability();
    getServicesData();
    getRevenueData();
  }, [])

  return (
    <div className="stats-container">
      {/* En-tête avec sélecteur de période */}
      <div className="stats-header">
        <h1>Statistiques</h1>
        <div className="timeframe-selector">
          <select 
            value={timeFrame}
            onChange={(e) => setTimeFrame(e.target.value)}
            className="timeframe-select"
          >
            <option value="week">Cette semaine</option>
            <option value="month">Ce mois</option>
            <option value="year">Cette année</option>
          </select>
          <ChevronDown size={20} className="select-icon" />
        </div>
      </div>

      {/* KPIs */}
      <div className="kpi-grid">
        {kpis.map((kpi, index) => (
          <div key={index} className="kpi-card">
            <div className="kpi-icon">{kpi.icon}</div>
            <div className="kpi-info">
              <span className="kpi-title">{kpi.title}</span>
              <div className="kpi-stats">
                <span className="kpi-value">{kpi.value}</span>
                <span className={`kpi-change ${kpi.trend}`}>
                  {kpi.change}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Graphiques */}
      <div className="charts-grid">
        {/* Chiffre d'affaires */}
        <div className="chart-card">
          <div className="chart-header">
            <h2>Chiffre d'affaires</h2>
            <TrendingUp size={20} />
          </div>
          <div className="chart-content">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Line 
                  type="monotone" 
                  dataKey="revenue" 
                  stroke="#0088FE" 
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Répartition des services */}
        <div className="chart-card">
          <div className="chart-header">
            <h2>Répartition des services</h2>
          </div>
          <div className="chart-content">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={servicesData}
                  innerRadius={60}
                  outerRadius={100}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {servicesData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <div className="chart-legend">
              {servicesData.map((service, index) => (
                <div key={index} className="legend-item">
                  <span 
                    className="legend-color"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <span className="legend-label">{service.name}</span>
                  <span className="legend-value">{service.percentage}%</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeauticianStats;