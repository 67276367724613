import "./Dashboard.css"

import { Outlet } from "react-router-dom"
import { createContext, useEffect, useState } from "react"

import Header from "../../components/dashboard/Header"
import UserCreation from "../../components/dashboard/UserCreation"
import SiteInfomation from "../../components/dashboard/SiteInfomation"

import beauticianApi from "../../api/beautician.api"
import userApi from "../../api/user.api"
import DeleteModal from "../../components/dashboard/DeleteModal"
import EditModal from "../../components/dashboard/EditModal"
import ErrorPopUp from "../../components/dashboard/ErrorPopUp"
import SuccessPopUp from "../../components/dashboard/SuccessPopUp"
import Comments from "../../components/dashboard/Comments"
import UserSheet from "../../components/dashboard/UserSheet"

export const dashboardContext = createContext({
    datas: [],
    showSiteInfomation: false,
    showUserCreationModal: false,
    userToDisplay: 10
})

const Dashboard = () => {
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeletionModal, setShowDeletionModal] = useState(false)
    const [showUserSheetModal, setShowUserSheetModal] = useState(false)
    const [showSiteInfomation, setShowSiteInformation] = useState(false)
    const [showUserCreationModal, setShowUserCreationModal] = useState(false)
    const [showBeauticianCommentaire, setShowBeauticianCommentaire] = useState(false);

    const [posts, setPosts] = useState([])

    const [activeID, setActiveID] = useState(null)
    const [userData, setUserData] = useState([])

    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [activePage, setActivePage] = useState('all');


    const fetchPosts = async () => {
        setIsLoading(true);
        try {
            let data = [];
            if (activePage === 'client') {
                data = await userApi.selectUsersByType(localStorage.getItem('token'), 'client');
            } else if (activePage === 'operator' || activePage === 'admin') {
                data = await userApi.selectUsersByType(localStorage.getItem('token'), 'operator');
            } else {
                let req = await beauticianApi.getAllBeauticians(activePage)
                data = req.data;
            }
            setPosts(data || []);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        fetchPosts();
    }, [reloadTrigger, activePage]);


    // fonctionnalités pour la suppression et la modification dans user sheet

    const [dynamicFunction, setDynamicFunction] = useState(() => {
        // Fonction par défaut
        return () => {};
    });
    const [isFromSheet, setIsFromSheet] = useState(false);
    // pour l'update dans userSheet
    const [dynamicUserData, setDynamicUserData] = useState([]);

    const handleExecuteFunction = () => {
        dynamicFunction(); // Appeler la fonction actuelle
    };

    useEffect(() => {
        handleExecuteFunction();
    }, [dynamicFunction]);

    // end fonctionnalités pour la modification et suppresionn dans user sheet

    return (
        <dashboardContext.Provider
            value={{
                datas: posts,
                activeID,
                setActiveID,
                userData,
                setError,
                setSuccess,
                setUserData,
                showEditModal: setShowEditModal,
                showDeletionModal: setShowDeletionModal,
                showUserSheetModal: setShowUserSheetModal,
                showSiteInformation: setShowSiteInformation,
                showeUserCreationModal: setShowUserCreationModal,
                showBeauticianCommentaire: showBeauticianCommentaire,
                setShowBeauticianCommentaire: setShowBeauticianCommentaire,
                reloadTrigger,
                setReloadTrigger,
                activePage: activePage,
                setActivePage,
                isLoading,
                setDynamicFunction,
                isFromSheet,
                setIsFromSheet,
                dynamicUserData,
                setDynamicUserData
            }}
        >

            <div className="w-100 pb-2 h-[100vh] flex flex-col gap-2 overflow-x-hidden">
                <Header
                    createUser={setShowUserCreationModal}
                    activePage={activePage}
                    setActivePage={setActivePage}
                />

                <div className="mx-4 mt-2 overflow-y-auto">
                    <Outlet />
                </div>

                {showEditModal && <EditModal />}
                {showDeletionModal && <DeleteModal />}
                {showSiteInfomation && <SiteInfomation />}
                {showUserSheetModal && <UserSheet />}
                {showUserCreationModal && <UserCreation />}
                {showBeauticianCommentaire && <Comments />}
                {error && <ErrorPopUp message={error} />}
                {success && <SuccessPopUp message={success} />}

            </div>
        </dashboardContext.Provider>
    )
}

export default Dashboard