import React, { useContext, useState } from 'react';
import { FaCamera, FaX } from 'react-icons/fa6';
import { FaSave } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

import beauticianApi from '../../api/beautician.api';
import userApi from '../../api/user.api';
import { dashboardContext } from '../../pages/dashboard/Dashboard';
import { API_URL } from '../../utils/constants';
import defaultProfle from '../../assets/images/default-profile.png';
import { sendEmaiBeauticianCreation } from '../../utils/mailServices';

const UserCreation = () => {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    //const [masquerNomPrenom, setMasquerNomPrenom] = useState(false);
    const [nomCommercial, setNomCommercial] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setEmail] = useState('');
    const [numero, setNumero] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [confirmMotDePasse, setConfirmMotDePasse] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [professionnelType, setProfessionnelType] = useState('indépendant');
    const [categoryBudget, setCategryBudget] = useState('economic');
    const { showeUserCreationModal, setReloadTrigger, activePage } = useContext(dashboardContext);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => setPreview(e.target.result);
            reader.readAsDataURL(file);
            setUploadStatus('');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            Swal.fire('Erreur', 'Veuillez sélectionner une image.', 'error');
            return '';
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch(`${API_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                return result.fileName;
            } else {
                const error = await response.json();
                setUploadStatus(`Erreur: ${error.error}`);
                return '';
            }
        } catch (error) {
            console.error('Error during upload:', error);
            Swal.fire('Erreur', "Erreur lors de l'upload.", 'error');
            return '';
        }
    };

    const deleteImage = async (imageName) => {
        try {
            const response = await fetch(`${API_URL}/delete-image-file`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ image_name: imageName }),
            });

            if (response.success) {
                return await response.json();
            } else {
                const error = await response.json();
                return error.message;
            }
        } catch (error) {
            console.error('Error during deleting file:', error);
            return error;
        }
    };

    const handleChangeProfessionnelType = (e) => {
        e.preventDefault();
        setProfessionnelType(e.target.value);
    }

    const validateFields = () => {
        if (!nom || !prenom || !numero || !email || (!adresse && !(activePage === "client")) || !motDePasse || !confirmMotDePasse) {
            Swal.fire('Erreur', 'Veuillez remplir les champs : nom, prénom(s), email, numero, adresse, et mot de passe!', 'error');
            return false;
        }

        if (!selectedFile) {
            Swal.fire('Erreur', 'Veuillez sélectionner une image.', 'error');
            return false;
        }

        if (motDePasse !== confirmMotDePasse) {
            Swal.fire('Erreur', 'Les mots de passe ne correspondent pas.', 'error');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            Swal.fire('Erreur', "L'adresse e-mail n'est pas valide.", 'error');
            return false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(numero)) {
            Swal.fire('Erreur', 'Le numéro de téléphone n\'est pas valide!', 'error');
            return false;
        }

        if (motDePasse.length < 6) {
            Swal.fire('Erreur', 'Le mot de passe doit contenir au moins 6 caractères.', 'error');
            return false;
        }

        return true;
    };

    const handleCreateUser = async () => {
        // if (!validateFields()) return;

        if (activePage === "client") {
            Swal.fire({
                title: 'Chargement...',
                text: 'Veuillez patienter...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const userData = {
                first_name: nom,
                last_name: prenom,
                phone: numero,
                email: email,
                password: motDePasse,
                user_type: 'client'
            };

            try {
                const response = await userApi.register(userData);

                if (response.user) {
                    showeUserCreationModal(false);
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire('Succès', 'Compte client créer avec succès.', 'success');
                    }, 200);
                    setReloadTrigger((prev) => !prev);
                }
                else {
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire('Erreur', response.message || 'Une erreur est survenue lors de l\'inscription.', 'error');
                    }, 200);
                }
            }
            catch (error) {
                Swal.close();
                setTimeout(() => {
                    Swal.fire('Erreur', error.message || 'Une erreur est survenue lors de l\'inscription.', 'error');
                }, 200);
            }
        }
        else {
            Swal.fire({
                title: 'Chargement...',
                text: 'Veuillez patienter...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const filename = await handleUpload();

            if (filename !== '') {
                const user = {
                    first_name: nom,
                    last_name: prenom,
                    phone: numero,
                    email: email,
                    password: motDePasse,
                    confirmPassword: confirmMotDePasse,
                };

                const token = localStorage.getItem('token');
                try {
                    const user_create = await userApi.createBeauticianUser(token, user);

                    if (user_create.user) {
                        const id = user_create.user.id;

                        try {
                            const create_beautician = await beauticianApi.createBeauticianProfile(token, {
                                user_id: id,
                                address: adresse,
                                description: '',
                                budget_category: categoryBudget,
                                profile_photo: filename,
                                business_name: nomCommercial,
                                type_beautician: professionnelType
                            });

                            if (create_beautician.success) {
                                showeUserCreationModal(false);
                                Swal.close();

                                sendEmaiBeauticianCreation(user.email, user.password);

                                setTimeout(() => {
                                    Swal.fire('Succès', 'Utilisateur créé avec succès.', 'success');
                                }, 200);
                                setReloadTrigger((prev) => !prev);
                            } else {
                                await userApi.deleteAccount(token, id);
                                await deleteImage(filename);
                                Swal.close();
                                setTimeout(() => {
                                    Swal.fire('Erreur', create_beautician.message || 'Erreur lors de la création du profil.', 'error');
                                }, 200);
                            }
                        } catch (error) {
                            await userApi.deleteAccount(token, id);
                            await deleteImage(filename);
                            Swal.close();
                            setTimeout(() => {
                                Swal.fire('Erreur', error.message || 'Erreur lors de la création du profil.', 'error');
                            }, 200);
                        }
                    } else {
                        await deleteImage(filename);
                        Swal.close();
                        setTimeout(() => {
                            Swal.fire('Erreur', user_create.message || 'Erreur lors de la création du profil.', 'error');
                        }, 200);
                    }
                } catch (error) {
                    await deleteImage(filename);
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire('Erreur', error.message || 'Erreur lors de la création de l\'utilisateur.', 'error');
                    }, 200);
                }
            }
        }
    };

    const getFormClass = () => {
        if (activePage === 'client') {
            return `relative w-1/2 overflow-y-auto p-6 flex flex-col gap-4 bg-white border rounded shadow`;
        }
        return 'relative w-1/2 h-[95vh] overflow-y-auto p-6 flex flex-col gap-4 bg-white border rounded shadow';
    }

    return (
        <div className="absolute w-screen h-screen flex items-center justify-center bg-gray-800 bg-opacity-80">
            <Form onSubmit={(e) => e.preventDefault()} className={getFormClass()}>
                <button className="absolute top-6 right-6" onClick={() => showeUserCreationModal(false)}>
                    <FaX />
                </button>
                <h1 className="text-center text-2xl text-gray-700 mb-4">
                    {activePage === "client" ? "Créer un client" : `Ajouter un utilisateur`}
                </h1>
                <div className=''>
                    {/* Choix de l'image */}
                    {activePage !== "client" &&
                        <label className="flex flex-col items-center gap-2 cursor-pointer mb-2">
                            {preview ? (
                                <img
                                    src={preview}
                                    alt="Preview"
                                    className="w-20 h-20 object-cover rounded-full border"
                                />
                            ) : <img
                                src={defaultProfle}
                                alt="Preview"
                                className="w-20 h-20 object-cover rounded-full border"
                            />
                            }
                            <div className='flex gap-2 items-center mb-2'><FaCamera />{preview ? <span>Modifier la photo de profil</span> : <span>Ajouter une photo de profil</span>}</div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                        </label>}

                    <div className="grid grid-cols-2 gap-3">
                        {activePage !== "client" && <React.Fragment>
                            <div className='flex flex-col gap-1'>
                                <Form.Label>Type de professionnelle*</Form.Label>
                                <select name="type_utilisateur" value={professionnelType} onChange={handleChangeProfessionnelType} id="type_utilisateur" className='px-3 py-2 rounded bg-gray-50 border border-gray-300'>
                                    <option value="indépendant">Professionnelle indépendant</option>
                                    <option value="établissement">Etablissement</option>
                                </select>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <Form.Label>Categorie de budget*</Form.Label>
                                <select name="type_utilisateur" value={categoryBudget} onChange={(e) => setCategryBudget(e.target.value)} id="type_utilisateur" className='px-3 py-2 rounded bg-gray-50 border border-gray-300'>
                                    <option value="economic">Economique</option>
                                    <option value="moderate">Moderée</option>
                                    <option value="premium">Premium</option>
                                </select>
                            </div>
                        </React.Fragment>
                        }
                        <div>
                            <Form.Label>Nom*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                            />
                        </div>
                        <div>
                            <Form.Label>Prénom(s)*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Prénom(s)"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                            />
                        </div>
                        {activePage !== "client" && <>
                            <div>
                                <Form.Label>Adresse postal*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Adresse postal"
                                    value={adresse}
                                    onChange={(e) => setAdresse(e.target.value)}
                                />
                            </div>
                            <div>
                                <Form.Label>Nom commercial</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nom commercial"
                                    value={nomCommercial}
                                    onChange={(e) => setNomCommercial(e.target.value)}
                                />
                            </div>
                        </>
                        }
                        <div>
                            <Form.Label>E-mail*</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="E-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <Form.Label>Numéro de téléphone*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Numéro de téléphone"
                                value={numero}
                                onChange={(e) => setNumero(e.target.value)}
                            />
                        </div>
                        <div>
                            <Form.Label>Mot de passe*</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Mot de passe"
                                value={motDePasse}
                                onChange={(e) => setMotDePasse(e.target.value)}
                            />
                        </div>
                        <div>
                            <Form.Label>Vérification mot de passe*</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Vérification mot de passe"
                                value={confirmMotDePasse}
                                onChange={(e) => setConfirmMotDePasse(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* Bouton Enregistrer */}
                <div className="flex justify-end mt-4 gap-3">
                    <button className='px-3 py-2 text-white bg-gray-700 rounded hover:bg-gray-800' onClick={() => showeUserCreationModal(false)}>Annuler</button>
                    <button
                        onClick={handleCreateUser}
                        className="px-4 py-2 flex gap-2 items-center text-white bg-[#bd193b] rounded"
                    >
                        <FaSave /> Enregistrer
                    </button>
                </div>
            </Form>
        </div>

    );
};

export default UserCreation;